module.exports = [{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-gatsby-cloud@5.9.0_gatsby@5.14.0_webpack@5.94.0/node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{"/*":["Referrer-Policy: strict-origin-when-cross-origin","Timing-Allow-Origin: *"]}},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-omni-font-loader@2.0.2_gatsby@5.14.0_react-helmet@6.1.0/node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Red Hat Display","file":"https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"}]},
    },{
      plugin: require('../../node_modules/.pnpm/@sentry+gatsby@7.91.0_gatsby@5.14.0_react@18.3.1/node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby@5.14.0_@swc+core@1.7.26_babel-eslint@10.1.0_esbuild@0.24.1_react-dom@18.3.1_react@18.3.1_typescript@4.9.5/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
